import useAnalyticsContext from '@hooks/useAnalyticsContext';
import { useAppSelector } from '@redux/hooks';
import React, { useCallback, useMemo } from 'react';
import type { InlineRfqSourceContent } from 'types/sourceContent';
import type { Decorated } from 'types/vendor';
import VendorAnalytics from '../../decorators/vendorAnalytics';
import RfqModal from '../../pages/Storefront/containers/InlineRFQ/components/RfqModal/RfqModal';
import { useInlineRfqForm } from '../../pages/Storefront/containers/InlineRFQ/hooks/useInlineRfqForm/useInlineRfqForm';
import RFQButton from '../../pages/Storefront/containers/cta_box/RfqButton';

export const PERSONALIZED_QUOTE_HEADER_TEXT = 'Get a personalized quote';

interface Props {
	sourceContent?: InlineRfqSourceContent;
	isPackagePricing?: boolean;
}

export const PricingCta = ({
	sourceContent = 'Pricing CTA',
	isPackagePricing = false,
}: Props) => {
	const formContext = useInlineRfqForm({
		initiator: sourceContent,
		headerText: PERSONALIZED_QUOTE_HEADER_TEXT,
	});
	const { track } = useAnalyticsContext();
	const vendor = useAppSelector((state) => state.vendor.vendor as Decorated);
	const vendorAnalytics = useMemo(() => new VendorAnalytics(vendor), [vendor]);

	const onCtaClick = useCallback(() => {
		if (isPackagePricing) {
			track({
				event: 'Clicked Lead on Detailed Pricing',
				properties: {
					...vendorAnalytics.baseEventProps(),
				},
			});
		}
		formContext.openModal();
	}, [
		formContext.openModal,
		track,
		isPackagePricing,
		vendorAnalytics.baseEventProps,
	]);

	return (
		<div>
			<RfqModal context={formContext} />
			<RFQButton
				type="message"
				color="secondary"
				size="md"
				label="Get a personalized quote"
				handleCTAClick={onCtaClick}
				testId="pricing-cta-personalized-quote"
			/>
		</div>
	);
};
