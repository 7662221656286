import type { MonthWeek } from '@components/calendar/types';
import { gpBaseUrl } from '@settings';
import {
	type Locale,
	addDays,
	addWeeks,
	addYears,
	differenceInCalendarDays,
	endOfISOWeek,
	endOfMonth,
	endOfWeek,
	format,
	getISOWeek,
	getWeek,
	getWeeksInMonth,
	startOfISOWeek,
	startOfMonth,
	startOfWeek,
} from 'date-fns';

export function daysToMonthWeeks(
	fromDate: Date,
	toDate: Date,
	options?: {
		ISOWeek?: boolean;
		locale?: Locale;
		weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
		firstWeekContainsDate?: 1 | 2 | 3 | 4 | 5 | 6 | 7;
	},
): MonthWeek[] {
	const toWeek = options?.ISOWeek
		? endOfISOWeek(toDate)
		: endOfWeek(toDate, options);
	const fromWeek = options?.ISOWeek
		? startOfISOWeek(fromDate)
		: startOfWeek(fromDate, options);

	const nOfDays = differenceInCalendarDays(toWeek, fromWeek);
	const days: Date[] = [];

	for (let i = 0; i <= nOfDays; i++) {
		days.push(addDays(fromWeek, i));
	}

	return days.reduce((result: MonthWeek[], date) => {
		const weekNumber = options?.ISOWeek
			? getISOWeek(date)
			: getWeek(date, options);

		const existingWeek = result.find(
			(value) => value.weekNumber === weekNumber,
		);
		if (existingWeek) {
			existingWeek.dates.push(date);
			return result;
		}
		result.push({
			weekNumber,
			dates: [date],
		});
		return result;
	}, []);
}

export function getMonthWeeks(
	month: Date,
	options: {
		locale: Locale;
		useFixedWeeks?: boolean;
		weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
		firstWeekContainsDate?: 1 | 2 | 3 | 4 | 5 | 6 | 7;
		ISOWeek?: boolean;
	},
): MonthWeek[] {
	const weeksInMonth: MonthWeek[] = daysToMonthWeeks(
		startOfMonth(month),
		endOfMonth(month),
		options,
	);

	if (options?.useFixedWeeks) {
		// Add extra weeks to the month, up to 6 weeks
		const nrOfMonthWeeks = getWeeksInMonth(month, options);
		if (nrOfMonthWeeks < 6) {
			const lastWeek = weeksInMonth[weeksInMonth.length - 1];
			const lastDate = lastWeek.dates[lastWeek.dates.length - 1];
			const toDate = addWeeks(lastDate, 6 - nrOfMonthWeeks);
			const extraWeeks = daysToMonthWeeks(
				addWeeks(lastDate, 1),
				toDate,
				options,
			);
			weeksInMonth.push(...extraWeeks);
		}
	}
	return weeksInMonth;
}

export const DATE_KEY_FORMAT = 'yyyy-MM-dd';

export const CALENDAR_RANGE_YEARS = 2;

interface FetchAvailabilityParams {
	storefrontId: string;
	startDate?: Date;
	endDate?: Date;
}
export const fetchAvailability = async ({
	storefrontId,
	startDate = new Date(),
	endDate = addYears(new Date(), CALENDAR_RANGE_YEARS),
}: FetchAvailabilityParams): Promise<Response> => {
	const controller = new AbortController();
	const timeout = setTimeout(() => controller.abort(), 5000);

	const options: RequestInit = {
		method: 'POST',
		body: JSON.stringify({
			storefrontId: storefrontId,
			dateRange: {
				start: format(startDate, DATE_KEY_FORMAT),
				end: format(endDate, DATE_KEY_FORMAT),
			},
		}),
		signal: controller.signal,
	};

	try {
		const response = await fetch(
			`${gpBaseUrl}/vendor/v1/availability/check`,
			options,
		);
		clearTimeout(timeout);
		return response;
	} catch (error) {
		if (error.name === 'AbortError') {
			throw new Error('Request timed out');
		}
		throw error;
	}
};
